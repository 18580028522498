<template>
  <div>
    <div class="page">
      <!-- <b-container class="hideMob">
        <b-row>
          <b-col>
            <h3 class="oomText FirstTitle">
              Order of Merit 
            </h3>
          </b-col>
        </b-row>
      </b-container> -->
      <!-- <b-container class="hideMob">
        <OomSlider
          :currentOom="currentOom"
          :previousOom="previousOom"
          :season="season"
          :develop="develop"
          :config="config"
          :year="year"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </b-container> -->
      <!-- <b-container class="hideMob">
        <b-row>
          <b-col>
            <h3 class="oomText FirstTitle">
              Featured Players
            </h3>
          </b-col>
        </b-row>
      </b-container>
      <template class="hideMob">
        <b-container class="featuredSection hideMob">
          <FeatureSlider 
            :featurePlayer="featurePlayer" 
            :Year="this.$route.params.season" 
            :config="config"
            :DEFIMAGESAMS3="DEFIMAGESAMS3"
            :develop="develop"
          />
        </b-container>
      </template> -->
      <div class="playerDiv">
        <PlayerTable 
          :season="season" 
          :config="config"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
          :develop="develop"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import OomSlider from "@/components/oomSlider.vue";
// import FeatureSlider from "@/components/featureSlider.vue";
import PlayerTable from "@/components/playerTable.vue";
import axios from "axios";
export default {
  name: "players",
  props: ["season", "config", "develop"],
  components: {
    // OomSlider,
    // FeatureSlider,
    PlayerTable,
  },
  data() {
    return {
      items: [],
      currentOom: [],
      previousOom: [],
      homepage: false,
      details: "details",
      cell: "cell",
      flag: "flag",
      filter: null,
      filterOn: [],
      year: parseInt(this.season) - 1,
      feature: [],
      oomData:[]
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    firstCall() {
        axios
        .get(
          process.env.VUE_APP_TIC_BASE +
          'apga' +
          "/tmticx?randomadd=" +
          new Date().getTime()
        )
        .then((response) => {
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3;
          console.log("this.DEFIMAGESAMS3")
          console.log(this.DEFIMAGESAMS3)
        }) 
    },
    indexAPGA(){
      axios
      .get(
        'https://api.asia.ocs-software.com/apga/profiles/index_ASN'
      )
      .then((response) => {
        // this.feature = response.data.MEMBERS.PLAYER;
        this.DEFIMAGESAMS3 = response.data.GENERAL.DEFIMAGESAMS3
      })
    },
    FeaturedPlayers(){
      axios
      .get(
        'https://api.asia.ocs-software.com/apga/profiles/index_ASN?featured=Y'
      )
      .then((response) => {
        this.featurePlayer = response.data.MEMBERS.PLAYER;
        console.log("this.featurePlayer")
        console.log(this.featurePlayer)
        this.DEFIMAGESAMS3 = response.data.GENERAL.DEFIMAGESAMS3
      })
    },

  },
  mounted() {
    this.FeaturedPlayers();
    this.indexAPGA();
      axios
      .get(
        process.env.VUE_APP_TIC_BASE +
         'apga' +
          "/" +
          this.season +
          "/" +
          this.season +
          "-ooms-oom-" +
          process.env.VUE_APP_ASIAN_OOM +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        if (Object.keys(response.data).length) {
         this.currentOom = response.data; 
        } else {
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              'apga' +
              "/" +
              this.year +
              "/" +
              this.year +
              "-ooms-oom-" +
              process.env.VUE_APP_ASIAN_OOM +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => {
            this.currentOom = response.data;
          }) 
        }
      })
  },
};
</script>

<style scoped>
.playerDiv {
  background-color: #F5F5F5;  
}
.featuredSection.container {
  margin-bottom: 30px;
}
.FirstTitle {
  margin-bottom: 50px;
}
.page {
  /* background-color: #f1f1f1; */
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 35px;
  margin-right: 15px;
}
.playerListTitle {
  font-size: 19px;
  padding-left: 10px;
  font-weight: 700;
  color: #1c457c;
  margin-top: 50px;
  text-align: center;
}

.oomText {
  font-size: 20px!important;
  padding-left: 10px;
  font-weight: 500;
  color: #0A3F7F;
  margin-top: 50px;
}

@media only screen and (max-width: 990px) {
  ::v-deep .allPlayers {
    color: #0A3F7F;
    font-size: 20px;
    font-weight: 500;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  ::v-deep .form-group {
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .hideMob {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  ::v-deep .carousel-item {
    margin-top: 3em !important;
  }
}

</style>